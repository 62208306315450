import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { GlobalNotificationsComponent } from './global-notifications/global-notifications.component';
import { HelpMenuComponent } from './help-menu/help-menu.component';
import { OverlayModule } from '@angular/cdk/overlay';
@NgModule({
    declarations: [
        LayoutComponent,
        BreadcrumbsComponent,
        SidenavComponent,
        GlobalNotificationsComponent,
        HelpMenuComponent
    ],
    imports: [
        MatSidenavModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        CommonModule,
        MatMenuModule,
        MatTooltipModule,
        RouterModule,
        OverlayModule
    ],
    exports: [LayoutComponent, BreadcrumbsComponent, SidenavComponent]
})
export class LayoutModule {}
