<ng-container *ngIf="hasProjectedTemplate">
    <ng-container *ngTemplateOutlet="ComponentType.projectedTemplate"></ng-container>
</ng-container>
<trds-date-range-picker-presets
    [hasTimePicker]="ComponentType.hasTimePicker"
    [presets]="ComponentType.datePresets"
></trds-date-range-picker-presets>
<div class="calendar-header">
    <div class="calendar-header-left">
        <button mat-button [matMenuTriggerFor]="menu" class="year-select-btn">
            {{ periodLabel }} <mat-icon class="year-select-icon">arrow_drop_down</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <div class="year-select-list">
                <button *ngFor="let year of years" mat-menu-item (click)="onYearSelectClicked(year)">{{ year }}</button>
            </div>
        </mat-menu>
    </div>
    <div class="calendar-header-right">
        <button mat-icon-button (click)="onPreviousClicked('month')" class="period-btn">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>

        <button mat-icon-button (click)="onNextClicked('month')" class="period-btn">
            <mat-icon>keyboard_arrow_right</mat-icon>
        </button>

        <button
            mat-icon-button
            matTooltip="Today"
            matTooltipPosition="above"
            matTooltipClass="trds-tooltip"
            (click)="onTodayBtnClick()"
            class="period-btn"
        >
            <mat-icon class="today-icon">calendar_today</mat-icon>
        </button>
    </div>
</div>
<ng-container *ngIf="ComponentType.hasTimePicker">
    <trds-time-range-inputs (created)="setTimeRangeInputsComponent($event)"></trds-time-range-inputs>
</ng-container>
