import {
    EventStatus,
    EventSide,
    IBaseEventDTO,
    IMSEventTypeDTO,
    EventTypeName,
    IBaseMSEventDTO,
    IExecutionEventDTO,
    IOrderEventDTO,
    ISnapshotDTO,
    IOrderBookEntryDTO,
    ITMEventDTO,
    IOrderEventDTOv2,
    SecurityType,
    PositionEffect,
    PutCall,
    ExerciseStyle
} from './events.models';
import { isNullOrUndefined, truncateDecimalPlaces } from './shared.functions';
import { ArrayPropsParser } from './shared.models';

export const MARKER_COLOR_BUY = '#0E171B';
export const MARKER_COLOR_SELL = '#ffffff';

export type MSEventType = OrderEvent | ExecutionEvent;
export type EventType = MSEventType | TMEvent;
export type ColoredEventType = MSEventType & {
    color?: string;
};

export type EventCandle = {
    DT: Date; // initializes to an invalid date
    Close: number;
    status: EventStatus;
    side: EventSide;
    quantity: string;
    account: string;
    exVenue: string;
};

export class BaseEvent {
    id: string;
    version: number;

    clientId: string;
    actorId: string;

    exVenue: string;
    quantity: string;
    usdNotional: number;
    originUsdNotional?: string;

    localCurrency: string;
    localNotional: number;

    ipAddress: string;
    matchingIpAddress: string;

    constructor(dto: IBaseEventDTO) {
        this.id = dto.id;
        this.version = dto.version;
        this.clientId = dto.clientId;
        this.actorId = dto.actorId;
        this.exVenue = dto.exVenue;
        this.quantity = dto.quantity;

        this.ipAddress = dto.ipAddress;
        this.matchingIpAddress = dto.matchingIpAddress;

        this.localCurrency = dto.localCurrency;
        this.localNotional = truncateDecimalPlaces(dto.localNotional, 5);

        this.originUsdNotional = dto.usdNotional?.toString() || null;
        this.usdNotional = truncateDecimalPlaces(dto.usdNotional, 5);
    }
}

export enum TMEventAlgoStatus {
    PASSED = 'PASSED',
    FAILED = 'FAILED'
}

export interface TMEventAlgoExecuted {
    name: string;
    status: TMEventAlgoStatus;
}

export class TMEvent extends BaseEvent {
    currency: string;
    eventType: string;
    externalAccountFunction: string;
    externalAccountId: string;
    externalAccountType: string;
    internalAccountId: string;
    status: string;
    timestamp: Date;
    transactionHash: string;
    transactionType: string;
    jurisdiction: string;
    location: string;
    clientProfile: string;
    legalEntity: string;
    executedAlgos: TMEventAlgoExecuted[];
    kytId: string;
    blockchain: string;
    entityIdentifier: string;

    constructor(dto: ITMEventDTO) {
        super(dto as IBaseEventDTO);

        this.currency = dto.currency;
        this.eventType = dto.eventType;
        this.externalAccountFunction = dto.externalAccountFunction;
        this.externalAccountId = dto.externalAccountId;
        this.externalAccountType = dto.externalAccountType;
        this.internalAccountId = dto.internalAccountId;
        this.status = dto.status;
        this.transactionHash = dto.transactionHash;
        this.transactionType = dto.transactionType;
        this.jurisdiction = dto.jurisdiction;
        this.location = dto.location;
        this.clientProfile = dto.clientProfile;
        this.legalEntity = dto.legalEntity;
        this.executedAlgos = [
            ...TMEvent.mapAlgoInfoToExecutedAlgos(dto?.algorithmInfo?.passedAlgorithmNames, TMEventAlgoStatus.PASSED),
            ...TMEvent.mapAlgoInfoToExecutedAlgos(dto?.algorithmInfo?.failedAlgorithmNames, TMEventAlgoStatus.FAILED)
        ];

        this.timestamp = new Date(dto.timestamp);
        this.kytId = dto.kytId;
        this.blockchain = dto.blockchain;
        this.entityIdentifier = dto.entityIdentifier;
    }

    private static mapAlgoInfoToExecutedAlgos(algos: string[], status: TMEventAlgoStatus): TMEventAlgoExecuted[] {
        if (algos) {
            return algos.map(algoName => ({ name: algoName, status: status }));
        }
        return [];
    }
}

export abstract class AbstractMSEvent extends BaseEvent {
    timestamp: number;
    eventType: EventTypeName;

    account: string;

    matchingClientId?: string;
    matchingAccount?: string;
    matchingActorId?: string;
    matchingOrderId?: string;

    status: EventStatus;
    side: EventSide;
    symbol: string;
    price: number;

    executedQuantity: string;

    dtoStatus: string;
    dtoSide: string;

    snapshot: Snapshot;
    securityType: SecurityType;
    exchangeSymbol: string;
    expirationDateTime: number;
    settleDateTime: number;
    positionEffect: PositionEffect;
    putCall: PutCall;
    strikePrice: string;
    strikeValue: string;
    fundingRate: string;
    contractMultiplier: string;
    cfiCode: string;
    exerciseStyle: ExerciseStyle;

    static init(event: IMSEventTypeDTO): OrderEvent | ExecutionEvent {
        if (AbstractMSEvent.isExecution(event)) {
            return new ExecutionEvent(event);
        } else if (AbstractMSEvent.isOrder(event)) {
            return new OrderEvent(event);
        } else {
            throw new TypeError(`Unexpected event: ${JSON.stringify(event)}`);
        }
    }

    static isExecution(event: IBaseMSEventDTO): event is IExecutionEventDTO {
        return event.eventType === 'msPrivateExecution';
    }

    static isOrder(event: IBaseMSEventDTO): event is IOrderEventDTO {
        return event.eventType === 'msPrivateOrder';
    }

    protected constructor(dto: IMSEventTypeDTO) {
        super(dto);

        this.timestamp = dto.timestamp;
        this.eventType = dto.eventType;

        this.account = dto.account;

        this.matchingClientId = dto.matchingClientId;
        this.matchingAccount = dto.matchingAccount;
        this.matchingActorId = dto.matchingActorId;
        this.matchingOrderId = dto.matchingOrderId;

        this.status = EventStatus[dto.status?.toUpperCase()] || EventSide.UNKNOWN;
        this.side = EventSide[dto.side?.toUpperCase()] || EventSide.UNKNOWN;
        this.symbol = dto.symbol;
        this.price = !isNullOrUndefined(dto.price) ? parseFloat(dto.price) : undefined;

        this.executedQuantity = dto.executedQuantity;

        this.dtoStatus = dto.status;
        this.dtoSide = dto.side;

        this.snapshot = new Snapshot(dto.snapshot);
        this.securityType = dto.securityType;
        this.exchangeSymbol = dto.exchangeSymbol;
        this.expirationDateTime = dto.expirationDateTime;
        this.settleDateTime = dto.settleDateTime;
        this.positionEffect = dto.positionEffect;
        this.putCall = dto.putCall;
        this.strikePrice = dto.strikePrice;
        this.strikeValue = dto.strikeValue;
        this.fundingRate = dto.fundingRate;
        this.contractMultiplier = dto.contractMultiplier;
        this.cfiCode = dto.cfiCode;
        this.exerciseStyle = dto.exerciseStyle;
    }

    abstract getImageName(): string;

    get candle(): EventCandle {
        return {
            side: this.side,
            status: this.status,
            DT: new Date(this.timestamp),
            account: this.account,
            quantity: this.quantity,
            exVenue: this.exVenue,
            Close: this.price
        };
    }

    get isValidStatus(): boolean {
        return this.status !== EventStatus.UNKNOWN;
    }
}

export class ExecutionEvent extends AbstractMSEvent {
    orderId: string;
    lastLiquidityInd: string;
    executedPrice: string;
    counterParty: string;
    counterPartyType: string;
    ipAddress: string;
    cumQty: string;
    leavesQty: string;
    tradeType: string;
    orderCapacity?: string;
    matchingOrderCapacity?: string;

    constructor(dto: IExecutionEventDTO) {
        super(dto);
        this.orderId = dto.orderId;
        this.lastLiquidityInd = dto.lastLiquidityInd;
        this.executedPrice = dto.executedPrice;
        this.counterParty = dto.counterParty;
        this.counterPartyType = dto.counterPartyType;
        this.ipAddress = dto.ipAddress;
        this.cumQty = dto.cumQty;
        this.leavesQty = dto.leavesQty;
        this.tradeType = dto.tradeType;
        this.orderCapacity = dto.orderCapacity;
        this.matchingOrderCapacity = dto.matchingOrderCapacity;
    }

    getImageName(): string {
        if (this.exVenue == 'Dex') {
            if (this.side === EventSide.BUY && this.status === EventStatus.NEW) {
                return 'buynew';
            } else if (this.side === EventSide.SELL && this.status === EventStatus.NEW) {
                return 'sellnew';
            }
        }
        return 'execution';
    }
}

export class OrderEvent extends AbstractMSEvent {
    avgPrice: string;
    exDestination: string;
    orderType: string;
    priceType: string;
    cumQty: string;
    leavesQty: string;
    timeInForce: string;
    capacity: string;
    deskId: string;
    algoName: string;
    origClOrdId: string;
    originationFirm: string;
    originationTrader: string;
    expireDateTime: number;
    ipAddress: string;
    tradeType: string;
    contingencyType: string;
    buIdentifier?: string[];
    executionPrice?: number;
    originalTransactionTime: number;

    constructor(dto: IOrderEventDTO) {
        super(dto);
        this.avgPrice = dto.avgPrice;
        this.exDestination = dto.exDestination;
        this.orderType = dto.orderType;
        this.priceType = dto.priceType;
        this.cumQty = dto.cumQty;
        this.leavesQty = dto.leavesQty;
        this.timeInForce = dto.timeInForce;
        this.capacity = dto.capacity;
        this.deskId = dto.deskId;
        this.algoName = dto.algoName;
        this.origClOrdId = dto.origClOrdId;
        this.originationFirm = dto.originationFirm;
        this.originationTrader = dto.originationTrader;
        this.expireDateTime = dto.expireDateTime;
        this.ipAddress = dto.ipAddress;
        this.tradeType = dto.tradeType;
        this.contingencyType = dto.contingencyType;
        this.buIdentifier = dto.buIdentifier;
        this.executionPrice = !isNullOrUndefined(dto.executionPrice) ? parseFloat(dto.executionPrice) : undefined;
        this.originalTransactionTime = dto.originalTransactionTime;
    }

    getImageName(): string {
        if (this.side === EventSide.BUY && this.status === EventStatus.NEW) {
            return 'buynew';
        } else if (
            this.side === EventSide.BUY &&
            (this.status === EventStatus.CANCELLED || this.status === EventStatus.CANCELED)
        ) {
            return 'buycancel';
        } else if (this.side === EventSide.BUY && this.status === EventStatus.AMEND) {
            return 'buyamend';
        } else if (this.side === EventSide.SELL && this.status === EventStatus.NEW) {
            return 'sellnew';
        } else if (this.side === EventSide.SELL && this.status === EventStatus.AMEND) {
            return 'sellamend';
        } else if (
            this.side === EventSide.SELL &&
            (this.status === EventStatus.CANCELLED || this.status === EventStatus.CANCELED)
        ) {
            return 'sellcancel';
        } else if (
            this.status === EventStatus.ACCEPTED ||
            this.status === EventStatus.FILLED ||
            this.status === EventStatus.PARTIALLY_FILLED
        ) {
            return 'execution';
        } else {
            throw new Error(`Unexpected status ${this.status} and side ${this.side}`);
        }
    }
}

export class OrderEventV2 extends OrderEvent {
    private static fieldsMap: ArrayPropsParser[] = [
        {
            name: 'id',
            parse: field => field || null
        },
        {
            name: 'origClOrdId',
            parse: field => field || null
        },
        {
            name: 'version',
            parse: field => Number(field) || null
        },
        {
            name: 'timestamp',
            parse: field => new Date(field).valueOf() || null
        },
        {
            name: 'expireDateTime',
            parse: field => new Date(field).valueOf() || null
        },
        {
            name: 'account',
            parse: field => field || null
        },
        {
            name: 'avgPrice',
            parse: field => Number(field) || null
        },
        {
            name: 'price',
            parse: field => Number(field)
        },
        {
            name: 'priceType',
            parse: field => field || null
        },
        {
            name: 'cumQty',
            parse: field => Number(field)
        },
        {
            name: 'quantity',
            parse: field => field || null
        },
        {
            name: 'leavesQty',
            parse: field => Number(field) || null
        },
        {
            name: 'capacity',
            parse: field => field || null
        },
        {
            name: 'usdNotional',
            parse: field => Number(field) || null
        },
        {
            name: 'side',
            parse: field => field || null
        },
        {
            name: 'symbol',
            parse: field => field || null
        },
        {
            name: 'status',
            parse: field => field || null
        },
        {
            name: 'orderType',
            parse: field => field || null
        },
        {
            name: 'lastLiquidityInd',
            parse: field => field || null
        },
        {
            name: 'algoName',
            parse: field => field || null
        },
        {
            name: 'clientId',
            parse: field => field || null
        },
        {
            name: 'deskId',
            parse: field => field || null
        },
        {
            name: 'originationFirm',
            parse: field => field || null
        },
        {
            name: 'exVenue',
            parse: field => field || null
        },
        {
            name: 'text',
            parse: field => field || null
        },
        {
            name: 'timeInForce',
            parse: field => field || null
        },
        {
            name: 'actorId',
            parse: field => field || null
        },
        {
            name: 'bestBid',
            parse: field => Number(field) || null
        },
        {
            name: 'bestAsk',
            parse: field => Number(field) || null
        }
    ];

    constructor(dto: IOrderEventDTOv2) {
        super(OrderEventV2.mapOrderEventV2ToV1(dto));
    }

    private static mapOrderEventV2ToV1(dto: IOrderEventDTOv2): IOrderEventDTO {
        const eventV1 = this.fieldsMap.reduce((acc, item, index) => {
            acc[item.name] = item.parse(dto[index]);
            return acc;
        }, {} as IOrderEventDTO);

        eventV1.eventType = 'msPrivateOrder';
        eventV1.snapshot = {
            BID: OrderEventV2.mapOrderEventSnapshotV2ToV1(
                JSON.parse(dto[29]) || [],
                eventV1.side,
                eventV1.symbol,
                eventV1.exVenue
            ),
            ASK: OrderEventV2.mapOrderEventSnapshotV2ToV1(
                JSON.parse(dto[30]) || [],
                eventV1.side,
                eventV1.symbol,
                eventV1.exVenue
            )
        };

        eventV1.localCurrency = dto[31];
        eventV1.localNotional = Number(dto[32]) || null;

        return eventV1;
    }

    private static mapOrderEventSnapshotV2ToV1(
        snapshot: [string, string][],
        side: string,
        symbol: string,
        source: string
    ): IOrderBookEntryDTO[] {
        return snapshot.map(snapshotData => {
            return ({
                side: side.toUpperCase(),
                symbol,
                price: snapshotData[0],
                quantity: snapshotData[1],
                source
            } as unknown) as IOrderBookEntryDTO;
        });
    }
}

export class Snapshot {
    bids: OrderBookEntry[] = [];
    asks: OrderBookEntry[] = [];

    constructor(obj: ISnapshotDTO) {
        if (obj) {
            this.asks = Array.isArray(obj.ASK)
                ? obj.ASK.map(a => new OrderBookEntry(a)).sort((a, b) => b.price - a.price)
                : [];
            this.bids = Array.isArray(obj.BID)
                ? obj.BID.map(b => new OrderBookEntry(b)).sort((a, b) => b.price - a.price)
                : [];
        }
    }
}

export class OrderBookEntry {
    tradingPair: string;
    source: string;
    side: string;
    price: number;
    quantity: number;
    internalQuantity: number;

    constructor(obj: IOrderBookEntryDTO) {
        Object.assign(this, obj);

        // TODO: add error protection for parsefloat returning NaN
        this.price = parseFloat(obj.price) || 0;
        this.quantity = parseFloat(obj.quantity) || 0;
        this.internalQuantity = parseFloat(obj.internalQuantity) || 0;
    }
}
