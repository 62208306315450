<button mat-button [matMenuTriggerFor]="helpMenu" color="primary" auto_id="btn_Help">
    <mat-icon fontSet="material-icons-outlined">help_outline</mat-icon>
</button>

<mat-menu #helpMenu="matMenu">
    <button
        mat-menu-item
        color="primary"
        (click)="openZendesk()"
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
        auto_id="listBox_HelpItems"
    >
        Submit A Request
    </button>

    <button mat-menu-item (click)="openHalpCenter()" auto_id="listBox_HelpItems">
        Help Center
    </button>
</mat-menu>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="zendeskOpen"
    [cdkConnectedOverlayHasBackdrop]="true"
    (backdropClick)="closeZendesk()"
    (detach)="closeZendesk()"
    cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
>
</ng-template>
