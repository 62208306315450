import { Component } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { isArray } from 'lodash';

import { TrdsLabelCellRendererParams } from 'src/app/ag-grid-tables/shared/models.shared';

@Component({
    selector: 'trds-label-multiple-cell-renderer',
    template: `
        <trds-label
            *ngFor="let item of items"
            tabindex="0"
            [attr.trds-flat]="hasAttributeFlat"
            [attr.aria-label]="item | dict: dictName"
            [matTooltip]="item | dict: dictName"
            matTooltipPosition="above"
            matTooltipClass="trds-tooltip"
        >
            {{ item | dict: dictName:true }}
        </trds-label>
    `
})
export class TrdsLabelMultipleCellRendererComponent implements ICellRendererAngularComp {
    items: string[];
    dictName: string;
    hasAttributeFlat: boolean;

    agInit(params: ICellRendererParams & TrdsLabelCellRendererParams): void {
        this.items = isArray(params.value) ? params.value : [params.value];
        this.dictName = params.dictName;
        this.hasAttributeFlat = params.hasAttributeFlat;
    }

    refresh(params: ICellRendererParams<any>): boolean {
        return false;
    }
}
