import { Component, EventEmitter, Input, Output, HostBinding, ElementRef } from '@angular/core';
@Component({
    selector: 'trds-spinner-button',
    templateUrl: './spinner-button.component.html',
    styleUrls: ['./spinner-button.component.scss']
})
export class SpinnerButtonComponent {
    @HostBinding('class') hostClass = 'trds-spinner-button';
    @Input() text: string;
    @Input() disabled: boolean;
    @Input() pending: boolean;
    @Input() color: string;
    @Input() icon: string;
    @Input() iconBeforeText = false;

    @Output() clickEvent: EventEmitter<void> = new EventEmitter();

    public flat: boolean;
    public stroked: boolean;
    public raised: boolean;
    public base = false;

    constructor(private elementRef: ElementRef) {
        if (this._hasHostAttributes('flat')) {
            this.flat = true;
        } else if (this._hasHostAttributes('stroked')) {
            this.stroked = true;
        } else if (this._hasHostAttributes('raised')) {
            this.raised = true;
        } else {
            this.base = true;
        }
    }

    private _getHostElement() {
        return this.elementRef.nativeElement;
    }

    /** Gets whether the button has attributes. */
    private _hasHostAttributes(...attributes: string[]) {
        return attributes.some(attribute => this._getHostElement().hasAttribute(attribute));
    }

    click(): void {
        this.clickEvent.emit();
    }
}
